import React, {useState, useRef} from 'react';

import { Button, message } from 'antd';

import {useForm} from 'react-hook-form';
import { addService } from '../../../../../../../../../services/auth-service/auth.service';

import './AddServiceModal.scss';

const AddServiceModal = props => {
  const {modalRef, closeModal, reloadCurrentPage} = props;
  const [coverImage, getCoverImage] = useState();
  const {register, handleSubmit} = useForm();
  const submitRef = useRef(null);
  const [loading, setLoading] = useState(false);
  
  const succesMessage = () => {
    message.success("Service saved");
  }

  const errorMessage = () => {
    message.error("Service not saved!!!");
  }

  const successAddService = response => {
    setLoading(false);
    reloadCurrentPage();
    closeModal(modalRef)
    succesMessage();
    console.log('response ==> ', response);
  }

  const failAddService = error => {
    setLoading(false);
    errorMessage();
    console.log('error ==> ', error);
  }
  
  const triggerSubmit = () => {
    submitRef.current.click();
  }
  
  const handleChange = file => {
    getCoverImage(file[0])
  }

  const onSubmit = data => {
    const formData = new FormData();
    setLoading(true);
    formData.append(
      "photo",
      coverImage,
      coverImage.name
    );

    addService(data, formData)
      .then(successAddService)
      .catch(failAddService);
  }

  const renderAddServiceForm = () => {
    return (
      <form className="FormContainer" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <input type="text"
               placeholder="Title"
               {...register('title', { required: true })}
               />
        <textarea type="textArea"
               placeholder="Description"
               rows="7"
               {...register('description')}
               />
        <input type="text"
               placeholder="Price"
               {...register('price', { required: true })}
               />
        <input type="text"
               placeholder="Time"
               {...register('time', { required: true })}
               />
        <input type="file"
              //  hidden={true}
               placeholder="Image"
               {...register('photo', { required: true })}
               onChange={e => handleChange(e.target.files)}
               />
        {/* <Button className="SubmitButton"
                loading={loading}
                onClick={triggerUpload}>
          Upload Photo
        </Button> */}
        <Button className="SubmitButton"
                loading={loading}
                onClick={triggerSubmit}
                >
          Add Service
        </Button>
        <button type="submit"
                hidden={true}
                ref={submitRef}/>
      </form>
    )
  }

  return (
    <div className="AddServiceModal">
      {renderAddServiceForm()}
    </div>
  )
}

export default AddServiceModal;
