import React, {useState, useEffect, useRef} from 'react';

import { getServicesHome } from '../../../services/auth-service/auth.service';

import ModalDialog from '../../../components/ModalDialog/ModalDialog';
import ServiceDetailsModal from './ServiceDetailsModal/ServiceDetailsModal';

import './ServicesComponent.scss';

const ServicesComponent = () => {
  const modalRef = useRef(null);
  const [services, getServicesData] = useState();
  const [serviceData, getServiceData] = useState();
  const [loading, setLoading] = useState(true);

  const getServicesFail = error => {
    setLoading(false);
    console.log(error);
  }

  const getServicesSuccess = response => {
    setLoading(false);
    getServicesData(response.data.data);
  }

  useEffect(() => {
    setLoading(true);
    getServicesHome()
      .then(getServicesSuccess)
      .catch(getServicesFail);
  },[])

  const serviceDetails = data => {
    getServiceData(data);
    openModal(modalRef);
  }

  const openModal = ref => {
    ref.current.openModal();
  }

  const closeModal = ref => {
    ref.current.closeModal();
  }

  const renderServiceDetailsModal = () => {
    return (
      <ModalDialog ref={modalRef}
                    title={serviceData?.title}>
            <ServiceDetailsModal closeModal={closeModal}
                                  modalRef={modalRef}
                                  data={serviceData}/>
      </ModalDialog>
    )
  }

  const renderService = data => {
    const {id, title, photo, price, time} = data;

    return (
      <div className="col-lg-3 col-md-4 col-sm-6" key={id}>
        <div className="price-item" onClick={serviceDetails.bind(null, data)}>
          <div className="price-img">
            <img src={process.env.REACT_APP_API_URL + '/' +  photo} alt="alexglad" />
          </div>
          <div className="price-text">
            <h2>{title}</h2>
            <h3>{time} minuter </h3>
            <h3>{price} kr</h3>
          </div>
        </div>
      </div>
    )
  }

  const renderServices = () => {
    return services?.map(renderService)
  }

  const servicesComponent = () => {
  return (
    <div className="ServicesComponent">
      <div className="price">
        <div className="container">
          <div className="section-header text-center">
            <p>Vårt bästa pris</p>
            <h2>Vi erbjuder bästa pris i staden</h2>
          </div>
          <div className="row">
            {loading ? "Loading..." : renderServices()}
          </div>
        </div>
      </div>   
      {renderServiceDetailsModal()}  
    </div>
  );
  }

  return servicesComponent();
}

export default ServicesComponent;
