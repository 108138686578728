import React from 'react';
import {Router} from '@reach/router';

import{ROUTES} from '../../routes';

import HomePage from './HomePage/HomePage';
import AboutUsPage from './AboutUsPage/AboutUsPage';
import ContactPage from './ContactPage/ContactPage';
import GalleryPage from './GalleryPage/GalleryPage';
import ServicesPage from './ServicesPage/ServicesPage';
import SingleGalleryPage from './SingleGalleryPage/SingleGalleryPage';
import SingleServicePage from './SingleServicePage/SingleServicePage';

import HeaderComponent from '../../components/HeaderComponent/HeaderComponent'
import FooterComponent from '../../components/FooterComponent/FooterComponent'

const MainView = () => {
  return (
    <div>
      <HeaderComponent/>
      <Router>
        <HomePage path={ROUTES.ROOT} />
        <AboutUsPage path={ROUTES.ABOUT} />
        <ContactPage path={ROUTES.CONTACT} />
        <GalleryPage path={ROUTES.GALLERY} />
        <SingleGalleryPage path={`${ROUTES.GALLERY}/:galleryId`} />
        <ServicesPage path={ROUTES.SERVICES} />
        <SingleServicePage path={`${ROUTES.SERVICE}/:serviceId`} />
      </Router>
      <FooterComponent/>
    </div>
  );
}

export default MainView;
