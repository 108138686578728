import React, {useState, useEffect}  from 'react';
import { Image } from 'antd';

import { getPhotos } from '../../../services/auth-service/auth.service';

import './SingleGalleryPage.scss';

const SingleGalleryPage = props => {
  const {galleryId} = props;
  const [photosData, getPhotosData] = useState();
  const [albumData, getAlbumData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const getPhotosFail = error => {
    setLoading(false);
    console.log(error);
  }

  const getPhotosSuccess = response => {
    setLoading(false);
    getPhotosData(response.data[0]);
    getAlbumData(response.data[1]);
  }

  useEffect(() => {
    setLoading(true);
    getPhotos(galleryId)
    .then(getPhotosSuccess)
    .catch(getPhotosFail);
  },[galleryId])

  const renderImage = data => {
    const {id, photo} = data;
    return (
      <Image
        width={250}
        src={process.env.REACT_APP_API_URL + '/' + photo}
        key={id}
      />
    )
  }

  const renderImages = () => {
    return (
      <div className="PhotosContainer">
        <Image.PreviewGroup>
          {photosData?.map(renderImage)}
        </Image.PreviewGroup>
      </div>
    )
  }

  const renderNoImages = () => {
    return (
      <div className="NoImageContainer">
        No Images
      </div>
    )
  }

  return (
    <div className="SingleGalleryPage">
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>{albumData?.name}</h2>
            </div>
            <div className="col-12">
              <a href="/">Home</a>
              <a href="/">Gallery</a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {/* {loading ? "Loading..." : renderPhotos()} */}
        {loading ? "Loading..." : photosData?.length === 0 ? renderNoImages() : renderImages()}
      </div>
    </div>
  );
}

export default SingleGalleryPage;
