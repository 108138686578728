import React, {useState, useRef, useEffect} from 'react';
import { Button, message } from 'antd';

import './AdminGalleryPage.scss';

import ModalDialog from '../../../../../../../../../components/ModalDialog/ModalDialog';
import { getAlbums, deleteAlbum} from '../../../../../../../../../services/auth-service/auth.service';
import {showGalleryPhotosPage} from '../../../../../../../../../services/navigation/gallery-navigation/gallery-navigation.service';

import AddGalleryModal from './AddGalleryModal/AddGalleryModal';


// import AdminGalleryTable from './AdminGalleryTable/AdminGalleryTable';

// import { UploadOutlined } from '@ant-design/icons';

const AdminGalleryPage = () => {

  const modalRef = useRef(null);
  const deleteModalRef = useRef(null);
  const [albums, getAlbumsData] = useState();
  const [albumID, getAlbumID] = useState();
  
  const [loading, setLoading] = useState(true);
  // const [deleteAlbumLoading, setDeleteAlbumLoading] = useState(false);
  const [reloadGallery, setReloadGallery] = useState(0)

  const getAlbumsFail = error => {
    setLoading(false);
    console.log(error);
  }

  const getAlbumsSuccess = response => {
    setLoading(false);
    getAlbumsData(response.data.data);
  }

  useEffect(() => {
    setLoading(true);
    getAlbums()
      .then(getAlbumsSuccess)
      .catch(getAlbumsFail);
  },[reloadGallery])

  const navigateToGalleryPhotos = albumId => {
    showGalleryPhotosPage(albumId);
  }

  const deleteAlbumFailed = error => {
    console.log(error);
    message.error("Gallery not deleted!!!");
  }

  const deleteAlbumSuccess = () => {
    closeModal(deleteModalRef);
    setReloadGallery(reloadGallery + 1);
    message.success("Gallery deleted");
  }

  const deleteGallery = albumId => {
    deleteAlbum(albumId)
      .then(deleteAlbumSuccess)
      .catch(deleteAlbumFailed);
  }

  // const renderTable = () => {
  //   return <AdminGalleryTable data={albums} />
  // }

  const openModal = ref => {
    ref.current.openModal();
  }

  const closeModal = ref => {
    ref.current.closeModal();
  }

  const renderAddGalleryModal = () => {
    return (
      <ModalDialog ref={modalRef}
                    title="Add Gallery">
            <AddGalleryModal closeModal={closeModal}
                              modalRef={modalRef}
                              reloadCurrentPage={reloadCurrentPage}/>
      </ModalDialog>
    )
  }


  const renderDeleteGalleryModal = () => {
    return (
      <ModalDialog className="DeleteModal"
                    ref={deleteModalRef}
                    title="Delete Gallery">
        <div className="ModalConfirmText">Are you sure you want to delete this gallery?</div>
        <div className="ModalButtonContainer">
          <Button loading={loading}
                  onClick={closeModal.bind(null, deleteModalRef)}
              >
            Cancel
          </Button>
          <Button loading={loading}
                  onClick={deleteGallery.bind(null, albumID)}
              >
            Delete
          </Button>
        </div>
      </ModalDialog>
    )
  }

  const deleteGalleryModal = id => {
    getAlbumID(id);
    openModal(deleteModalRef);
  }

  const reloadCurrentPage = () => {
    setReloadGallery(reloadGallery +1 )
  }

  const renderAlbum = (data, index) => {
    const {id, name, cover_image} = data;
    return (
      <div className="GalleryItemHolder" key={index}>
        <div className="NameHolder">{name}</div>
        <div className="ImageHolder">
          <img src={process.env.REACT_APP_API_URL + '/' + cover_image} alt="alexglad" />
        </div>
        <div className="ButtonHolder">
          <Button onClick={navigateToGalleryPhotos.bind(null, id)} type="primary">
            <i className="fas fa-plus"></i>
          </Button>
          <Button onClick={deleteGalleryModal.bind(null, id)} danger type="primary">
            <i className="fas fa-trash-alt"></i>
          </Button>
        </div>
      </div>
    )
  }

  const renderAlbums = () => {
    return (
      albums?.map(renderAlbum)
    )
  }

  return (
    <div className="AdminGalleryPage">
      <div className="AddGalleryButtonContainer">
        <Button onClick={openModal.bind(null, modalRef)} type="primary">Add Gallery</Button>
      </div>
      <div className="TableHeader">
        <div className="">
          Name
        </div>
        <div className="">
          Photo
        </div>
        <div className="ActionsHolder">
          Action
        </div>
      </div>
      <div className="GalleryConainer">
        {loading ? "Loading..." :renderAlbums()}
      </div>
      {renderAddGalleryModal()}
      {renderDeleteGalleryModal()}
    </div>
  );
}

export default AdminGalleryPage;
