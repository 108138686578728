import React, {useEffect, useState, useRef} from 'react';
import { Image, Button } from 'antd';

import ModalDialog from '../../../../../../../../../components/ModalDialog/ModalDialog';
import {getPhotos, deletePhoto} from '../../../../../../../../../services/auth-service/auth.service';

import AddPhotosModal from './AddPhotosModal/AddPhotosModal';

import './AdminPhotosPage.scss';

const AdminPhotosPage = props => {
  const {albumId} = props;
  const modalRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [photosData, getPhotosData] = useState();
  const [albumData, getAlbumData] = useState();

  const [reloadPhotos, setReloadPhotos] = useState(0)


  const getPhotosFail = error => {
    setLoading(false);
    console.log(error);
  }

  const getPhotosSuccess = response => {
    setLoading(false);
    getPhotosData(response.data[0]);
    getAlbumData(response.data[1]);
  }

  useEffect(() => {
    setLoading(true);
    getPhotos(albumId)
      .then(getPhotosSuccess)
      .catch(getPhotosFail);
  },[reloadPhotos, albumId])

  const deletePhotosFail = error => {
    console.log("error", error);
  }

  const deletePhotosSuccess = () => {
    reloadCurrentPage();
  } 

  const deletePhotoById = photoId => {
    deletePhoto(photoId)
      .then(deletePhotosSuccess)
      .catch(deletePhotosFail);
  }
  
  const renderImage = data => {
    const {id, photo} = data;
    return (
      <div className="ImageContainer" key={id}>
        <Image
          width={200}
          src={process.env.REACT_APP_API_URL + '/' + photo}
        />
        <div className="DeleteImageContainer" onClick={deletePhotoById.bind(null, id)}>
          <i className="fas fa-trash-alt"></i>
        </div>
      </div>
    )
  }

  const renderImages = () => {
    return (
      <div className="PhotosContainer">
        <Image.PreviewGroup>
          {photosData?.map(renderImage)}
        </Image.PreviewGroup>
      </div>
    )
  }

  const showAddPhotosModal = () => {
    modalRef.current.openModal();
  }

  const hideAddPhotosModal = () => {
    modalRef.current.closeModal();
  }

  const renderAddGalleryModal = () => {
    return (
      <ModalDialog  ref={modalRef}
                    title="Add Photos">
            <AddPhotosModal hideAddPhotosModal={hideAddPhotosModal}
                            reloadCurrentPage={reloadCurrentPage}
                            albumId={albumId}/>
      </ModalDialog>
    )
  }

  const reloadCurrentPage = () => {
    setReloadPhotos(reloadPhotos +1 )
  }

  return (
    <div className="AdminPhotosPage">
      <div className="GalleryTitle">
        {albumData?.name}
      </div>
      <div className="AddGalleryButtonContainer">
        <Button onClick={showAddPhotosModal} type="primary">Add Photos</Button>
      </div>
      {renderAddGalleryModal()}
      {loading ? "Loading..." : renderImages()}
    </div>
  )
}

export default AdminPhotosPage;
