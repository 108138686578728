import React from 'react';

import './DashboardPage.scss';

import {showDashboard} from '../../../services/navigation/dashboard-navigation/dashboard-navigation.service';
import {getDashboardBaseRoute} from '../../../services/navigation/dashboard-navigation/dashboard-navigation-routes.service';
import {showGallery} from '../../../services/navigation/gallery-navigation/gallery-navigation.service';
import {getGalleryBaseRoute} from '../../../services/navigation/gallery-navigation/gallery-navigation-routes.service';
import {showServices} from '../../../services/navigation/services-navigation/services-navigation.service';
import {getServicesBaseRoute} from '../../../services/navigation/services-navigation/services-navigation-routes.service';


import MainDashboardHeader from './MainDashboardHeader/MainDashboardHeader';
import MainView from './MainView/MainView';

const DashboardPage = () => {

  const navigationItems = [
    {
      id: 0,
      name: "Dashboard",
      navigate: showDashboard,
      link: getDashboardBaseRoute(),
    },
    {
      id: 1,
      name: "Gallery",
      navigate: showGallery,
      link: getGalleryBaseRoute(),
    },
    {
      id: 2,
      name: "Services",
      navigate: showServices,
      link: getServicesBaseRoute(),
    }
  ];

  return (
    <div className="DashboardPage">
      <MainDashboardHeader />
      <MainView navigationItems={navigationItems} />
    </div>
  )
}

export default DashboardPage;
