import axios from 'axios';

export const urls = {
  userData: '/api/test'
};

export const setUserLocalData = data => {
  localStorage.setItem('userData',JSON.stringify(data));
};

export const getUserLocalData = () => {
  const userData = localStorage.getItem('userData');
  return userData ? JSON.parse(userData): {}
};

export const removeUserLocalData = () => {
  localStorage.removeItem('userData');
};

export const getUserData = id => {
  return axios.get(`${urls.userData}${id}`);
};
