import React, {useEffect, useState} from 'react';
import LOGO from '../../images/logo-mini.png';

import './HeaderComponent.scss';

const HeaderComponent = () => {

  const url = window.location.href.split('/');
  const lastSegment = url.pop();

  const classes = {
    home: {
      active: "nav-item nav-link active",
      inactive: "nav-item nav-link"
    },
    about: {
      active: "nav-item nav-link active",
      inactive: "nav-item nav-link"
    },
    service: {
      active: "nav-item nav-link active",
      inactive: "nav-item nav-link"
    },
    gallery: {
      active: "nav-item nav-link active",
      inactive: "nav-item nav-link"
    },
    contact: {
      active: "nav-item nav-link active",
      inactive: "nav-item nav-link"
    },
  }
 
  const [scrolled,setScrolled] = useState(false);

  const handleScroll= () => {
    const offset=window.scrollY;

    if(offset > 0 ){
      setScrolled(true);
    }
    else{
      setScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  const navbarClasses=['navbar', 'navbar-expand-lg', 'bg-dark', 'navbar-dark'];
  if(scrolled){
    navbarClasses.push('nav-sticky');
  }
  
  return (
    <div className="HeaderComponent">
      <div className="top-bar d-none d-md-block">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="top-bar-left">
                {/* <div className="text">
                  <h2>8:00 - 9:00</h2>
                  <p>Opening Hour Mon - Fri</p>
                </div> */}
                <div className="text">
                  <h2>0739605170</h2>
                  <p>Ring oss för möte</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="top-bar-right">
                <div className="social">
                  {/* <a href="/"><i className="fab fa-twitter"></i></a> */}
                  <a href="https://www.facebook.com/AlexGlad_HairCare-103173041965929" target="_blank" rel="noopener noreferrer" ><i className="fab fa-facebook-f"></i></a>
                  {/* <a href="/"><i className="fab fa-linkedin-in"></i></a> */}
                  <a href="https://www.instagram.com/alexglad_haircare/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={navbarClasses.join(' ')}>
        <div className="container-fluid">
          <img className="logo" src={LOGO} alt="alexglad" />
          <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
            <div className="navbar-nav ml-auto">
              <a href="/" className={lastSegment === "" ? classes.home['active'] : classes.home['inactive']}>Hem</a>
              <a href="/about" className={lastSegment === "about" ? classes.about['active'] : classes.about['inactive']}>Om oss</a>
              <a href="/services" className={lastSegment === "services" ? classes.service['active'] : classes.service['inactive']}>Tjänster</a>              
              <a href="/gallery" className={lastSegment === "gallery" ? classes.gallery['active'] : classes.gallery['inactive']}>Galleri</a>
              <a href="/contact" className={lastSegment === "contact" ? classes.contact['active'] : classes.contact['inactive']}>Kontakt</a>
              <a href="https://www.bokadirekt.se/places/alex-glad-hair-care-40921" target="_blank" rel="noopener noreferrer" className="nav-item nav-link">Boka tid</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderComponent;
