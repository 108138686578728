import React from 'react';
import {Router} from '@reach/router';

import{ROUTES} from './routes';

import MainView from './pages/MainView/MainView';
import AdminView from './pages/AdminView/AdminView';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

function App() {
  return (
   <Router>
      <MainView path={`${ROUTES.ROOT}/*`} />
      <AdminView path={`${ROUTES.ADMIN}/*`} />
   </Router>
  );
}

export default App;
