import React from 'react';

import AboutUsComponent from '../../components/AboutUsComponent/AboutUsComponent';
import './AboutUsPage.scss';


const AboutUsPage = () => {
  return (
    <div className="AboutUsPage">
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Om oss</h2>
            </div>
            <div className="col-12">
              <a href="/">Hem</a>
              <a href="/">Om oss</a>
            </div>
          </div>
        </div>
      </div>
      <AboutUsComponent />
    </div>
  );
}

export default AboutUsPage;
