import React from 'react';

import './AdminDashboardPage.scss';

const AdminDashboardPage = () => {

  return (
    <div className="AdminDashboardPage">
      AdminDashboardPage   das asd
    </div>
  )
}

export default AdminDashboardPage;
