import React from 'react';

import DEFAULTUSER from '../../../images/default-user-image.png';

import './TestimonialComponent.scss';

const TestimonialComponent = () => {
  return (
    <div className="TestimonialComponent">
        <div className="testimonial">
            <div className="container">
                <div className="owl-carousel testimonials-carousel">
                    <div className="testimonial-item">
                      <div className="ClientContainer">
                        <div className="ClientImageContainer">
                          <img src={DEFAULTUSER} alt="alexglad" />
                        </div>
                        <div className="ClientInfoContainer">
                          <h2>Client Name</h2>
                          <h3>Izabela M.</h3>
                        </div>
                      </div>
                      <p>
                          Fantastiskt! Jag är jättenöjd med min frisör. Aleksandra är mycket trevlig och jobba nogrann. Salong är rent och skönt, parkering finns framme. Jag rekommenderar varmt och ska absolut komma igen.
                      </p>
                    </div>
                    <div className="testimonial-item">
                      <div className="ClientContainer">
                        <div className="ClientImageContainer">
                        <img src={DEFAULTUSER} alt="alexglad" />
                        </div>
                        <div className="ClientInfoContainer">
                          <h2>Client Name</h2>
                          <h3>Dunja I.</h3>
                        </div>
                      </div>
                      <p>
                          Super trevlig upplevelse. Alex är en erfaren och professionell frisör. Hon uppfyllde alla mina önskningar. Jag är väldigt nöjd med den nya frisyren och jag rekommenderar verkligen Alex
                      </p>
                    </div>
                    <div className="testimonial-item">
                      <div className="ClientContainer">
                        <div className="ClientImageContainer">
                        <img src={DEFAULTUSER} alt="alexglad" />
                        </div>
                        <div className="ClientInfoContainer">
                          <h2>Client Name</h2>
                          <h3>Oscar O.</h3>
                        </div>
                      </div>
                      <p>
                          Mycket bra herrklippning och bra upplevelse ! Definitivt komma tillbaka nästa gång jag behöver gå till frisören.
                      </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default TestimonialComponent;
