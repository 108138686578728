import React, {useState, useEffect} from 'react';

import {showGalleryDetails} from '../../../services/navigation/navigation.service';
import { getAlbums} from '../../../services/auth-service/auth.service';

import './GalleryPage.scss';

const GalleryPage = () => {
  const [albums, getAlbumsData] = useState();
  const [loading, setLoading] = useState(true);
  
  const getAlbumsFail = error => {
    setLoading(false);
    console.log(error);
  }

  const getAlbumsSuccess = response => {
    getAlbumsData(response.data.data);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    getAlbums()
      .then(getAlbumsSuccess)
      .catch(getAlbumsFail);
  },[])

  const galleryDetails = galleryId => {
    showGalleryDetails(galleryId);
  }

  const ImageThumb = data => {
    const {id, name, cover_image} = data;
    return (
      <div className="col-lg-4 col-md-6 col-sm-12 GalleryImageContainer" key={id}>
        <div className="ImageEffect" onClick={galleryDetails.bind(null, id)}>
          <img src={process.env.REACT_APP_API_URL + '/' + cover_image} alt="img04"/>
          <div className="ImageCaption">
            <h2>{name}</h2>
          </div>     
        </div>
      </div>
    )
  }

  const renderAlbum = () => {
    console.log("albums ==> ", albums);
    return (
      albums?.map(ImageThumb)
    )
  }

  return (
    <div className="GalleryPage">
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Galleri</h2>
            </div>
            <div className="col-12">
              <a href="/">Hem</a>
              <a href="/">Galleri</a>
            </div>
          </div>
        </div>
      </div>
      <div className="Gallery">
          <div className="container">
            <div className="section-header text-center">
              <p>Frisör bildgalleri</p>
              <h2>Några bilder från vårt frisörgalleri</h2>
            </div>
            <div className="row gallery-container">
              {loading ? "Loading..." : renderAlbum()}
            </div>
          </div>
        </div>
    </div>
  );
}

export default GalleryPage;
