import React from 'react';
import { Layout } from 'antd';

import './MainView.scss';

import SideMenu from './components/SideMenu/SideMenu';
import MainContent from './components/MainContent/MainContent';

const MainView = props => {
  const {navigationItems} = props;

  return (
   <Layout className="MainView">
     <SideMenu navigationItems={navigationItems} />
     <MainContent />
   </Layout>
  )
}

export default MainView;
