import React from 'react';
import { Layout } from 'antd';

import LOGO from '../../../../images/logo-mini.png';

import './MainDashboardHeader.scss';

const MainDashboardHeader = () => {

  return (
    <Layout.Header className="MainDashboardHeader">
      <a href="/" target="_blank" rel="noopener noreferrer">
        <img className="logo" src={LOGO} alt="alexglad" />
      </a>
    </Layout.Header>
  )
}

export default MainDashboardHeader;
