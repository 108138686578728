import React from 'react';
import { Location, Link } from '@reach/router';
import { Layout, Menu } from 'antd';

import './SideMenu.scss';

const SideMenu = props => {

  const {SubMenu} = Menu;

  const getSelectedIndex = pathname => {
    const pathName = pathname;
    const navigationItems =[];
    props.navigationItems.map((navItem) => {
      if (navItem.submenu) {
        navItem.submenu.map((navSubItem) => {
          return navigationItems.push(navSubItem);
        })
      } else {
        return navigationItems.push(navItem);
      }
      return navigationItems;
    })
    return navigationItems.findIndex(matchByLinkPath.bind(null, pathName));
  };

  const getSiderAdditionalProps = () => {
    const mobile = {
      collapsed: props.collapsed,
      collapsible: true,
      collapsedWidth: 0
    };

    const desktop = {
      collapsed: false,
      collapsible: false,
      collapsedWidth: 0
    };

    return (window.innerWidth > 1000 ? desktop : mobile);
  };

  const matchByLinkPath = (pathName, item) => {
    const path = item.submenu ?
    item.submenu.map((subItem) => {
      return pathName.indexOf(subItem.link) !== -1;
    })
    : item.link.indexOf(pathName) !== -1;
    return path;
  };

  const renderMenu = item => {
    const menu = item.submenu ? (
      <SubMenu key={item.id} title={item.name} datakey={item.id}>
        {item.submenu.map(subItem => {
            return(
              <Menu.Item key={subItem.id} link={subItem.link}  datakey={subItem.id}>
                <Link to={subItem.link}>
                  {subItem.name}
                </Link>
              </Menu.Item>
              )
            })
        }
      </SubMenu>
    ) : (
      <Menu.Item key={item.id} link={item.link} datakey={item.id}>
        <Link to={item.link}>
          {item.name}
        </Link>
      </Menu.Item>
    );
            
    return menu;
  };

  const renderSideMenu = ({location}) => {
    const selectedId = getSelectedIndex(location.pathname);
    const additionalProps = getSiderAdditionalProps();

    return (
      <Layout.Sider theme="light" trigger={null} {...additionalProps} >
        <Menu
          mode='inline'
          selectedKeys={[`${selectedId}`]}
          defaultOpenKeys={['1']}>
          {props.navigationItems.map(renderMenu)}
        </Menu>
      </Layout.Sider>
    );
  };


  return (
    <Location>
      {renderSideMenu}  
    </Location>
  )
}

export default SideMenu;
