import {navigate} from '@reach/router';

import {ROUTES} from '../../routes';

export const showServiceDetails = serviceId => {
  return navigate(`${ROUTES.SERVICE}/${serviceId}`);
}

export const showGalleryDetails = galleryId => {
  return navigate(`${ROUTES.GALLERY}/${galleryId}`);
}

export const showAdminGallery = () => {
  return navigate(`${ROUTES.ADMIN}/gallery`);
}