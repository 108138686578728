import React, {useState, useRef, useEffect} from 'react';
import { Button, message} from 'antd';

import ModalDialog from '../../../../../../../../components/ModalDialog/ModalDialog';
import { getServices, deleteService } from '../../../../../../../../services/auth-service/auth.service';

import AddServiceModal from './AddServiceModal/AddServiceModal';
import EditServiceModal from './EditServiceModal/EditServiceModal';

import './AdminServicesPage.scss';

const AdminServicesPage = () => {
  const modalRef = useRef(null);
  const modalEditService = useRef(null);
  const deleteModalRef = useRef(null);
  const [services, getServicesData] = useState();
  const [serviceData, getServiceData] = useState();
  const [serviceId, getServiceID] = useState();
  const [loading, setLoading] = useState(true);
  const [reloadService, setReloadService] = useState(0)

  const getServicesFail = error => {
    setLoading(false);
    console.log(error);
  }

  const getServicesSuccess = response => {
    setLoading(false);
    getServicesData(response.data.data);
  }

  useEffect(() => {
    setLoading(true);
    getServices()
      .then(getServicesSuccess)
      .catch(getServicesFail);
  },[reloadService])

  const openModal = ref => {
    ref.current.openModal();
  }

  const closeModal = ref => {
    ref.current.closeModal();
  }

  const renderAddServiceModal = () => {
    return (
      <ModalDialog ref={modalRef}
                    title="Add Service">
            <AddServiceModal closeModal={closeModal}
                              modalRef={modalRef}
                              reloadCurrentPage={reloadCurrentPage}/>
      </ModalDialog>
    )
  }

  const renderEditServiceModal = () => {
    return (
      <ModalDialog ref={modalEditService}
                    title="Edit Service">
            <EditServiceModal closeModal={closeModal}
                              modalRef={modalEditService}
                              serviceData={serviceData}
                              reloadCurrentPage={reloadCurrentPage}/>
      </ModalDialog>
    )
  }

  const renderDeleteServiceModal = () => {
    return (
      <ModalDialog className="DeleteModal"
                    ref={deleteModalRef}
                    title="Delete Service">
        <div className="ModalConfirmText">Are you sure you want to delete this service?</div>
        <div className="ModalButtonContainer">
          <Button loading={loading}
                  onClick={closeModal.bind(null, deleteModalRef)}
              >
            Cancel
          </Button>
          <Button loading={loading}
                  onClick={deleteServiceById.bind(null, serviceId)}
              >
            Delete
          </Button>
        </div>
      </ModalDialog>
    )
  }

  const openEditServiceModal = data => {
    getServiceData(data)
    openModal(modalEditService);
  }

  const reloadCurrentPage = () => {
    setReloadService(reloadService +1 )
  }

  const deleteServiceFailed = error => {
    console.log(error);
    message.error("Service not deleted!!!");
  }

  const deleteServiceSuccess = () => {
    closeModal(deleteModalRef);
    reloadCurrentPage();
    message.success("Service deleted");
  }

  const deleteServiceById = albumId => {
    deleteService(albumId)
      .then(deleteServiceSuccess)
      .catch(deleteServiceFailed);
  }

  const deleteServiceModal = id => {
    getServiceID(id);
    openModal(deleteModalRef);
  }

  const renderService = (data, index) => {
    const {id, title, price, time, description, photo} = data;
    return (
      <div className="ServiceItemHolder" key={index}>
        <div className="NameHolder">{title}</div>
        <div className="ImageHolder">
          <img src={process.env.REACT_APP_API_URL + '/' + photo} alt="alexglad" />
        </div>
        <div className="NameHolder">{price}</div>
        <div className="NameHolder">{time}</div>
        <div className="DescriptionHolder">{description}</div>
        <div className="ButtonHolder">
          <Button type="primary" onClick={openEditServiceModal.bind(null, data)}>
            <i className="far fa-edit"></i>
          </Button>
          <Button danger type="primary" onClick={deleteServiceModal.bind(null, id)}>
            <i className="fas fa-trash-alt"></i>
          </Button>
        </div>
      </div>
    )
  }

  const renderServices = () => {
    return (
      services?.map(renderService)
    )
  }

  return (
    <div className="AdminServicesPage">
      <div className="AddPhotosButtonContainer">
        <Button onClick={openModal.bind(null, modalRef)} type="primary">Add Service</Button>
      </div>  
      <div className="TableHeader">
        <div className="">
          Name
        </div>
        <div className="">
          Photo
        </div>
        <div className="">
          Price
        </div>
        <div className="">
          Time
        </div>
        <div className="">
          Description
        </div>
        <div className="ActionsHolder">
          Action
        </div>
      </div>
      <div className="ServicesContainer">
        {loading ? "Loading..." :renderServices()}
      </div>
      {renderAddServiceModal()}
      {renderEditServiceModal()}
      {renderDeleteServiceModal()}
    </div>
  )
}

export default AdminServicesPage;
