import React, {useState, useEffect, useRef} from 'react';

import { getServices } from '../../../services/auth-service/auth.service';

import ModalDialog from '../../../components/ModalDialog/ModalDialog';

import ServiceDetailsModal from './ServiceDetailsModal/ServiceDetailsModal';

import './ServicesPage.scss';

const ServicesPage = () => {
  const modalRef = useRef(null);
  const [services, getServicesData] = useState();
  const [serviceData, getServiceData] = useState();
  const [loading, setLoading] = useState(true);

  const getServicesFail = error => {
    setLoading(false);
    console.log(error);
  }

  const getServicesSuccess = response => {
    setLoading(false);
    getServicesData(response.data.data);
  }

  useEffect(() => {
    setLoading(true);
    getServices()
      .then(getServicesSuccess)
      .catch(getServicesFail);
  },[])

  const serviceDetails = data => {
    getServiceData(data);
    openModal(modalRef);
  }

  const openModal = ref => {
    ref.current.openModal();
  }

  const closeModal = ref => {
    ref.current.closeModal();
  }

  const renderServiceDetailsModal = () => {
    return (
      <ModalDialog ref={modalRef}
                    title={serviceData?.title}>
            <ServiceDetailsModal closeModal={closeModal}
                                  modalRef={modalRef}
                                  data={serviceData}/>
      </ModalDialog>
    )
  }

  const renderService = data => {
    const {id, title, photo} = data;
    return (
      <div className="col-lg-3 col-md-6" key={id}>
        <div className="team-item" onClick={serviceDetails.bind(null, data)}>
          <div className="team-img">
            <img src={process.env.REACT_APP_API_URL + '/' + photo} alt="alexglad" />
          </div>
          <div className="team-text">
            <h2>{title}</h2>
          </div>
        </div>
      </div>
    );
  }

  const renderServices = () => {
    return services?.map(renderService)
  }

  return (
    <div className="ServicesPage">
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Tjänster</h2>
            </div>
            <div className="col-12">
              <a href="/">Hem</a>
              <a href="/ ">Tjänster</a>
            </div>
          </div>
        </div>
      </div>
      <div className="team">
          <div className="container">
            <div className="section-header text-center">
              <p>Våra salong tjänster</p>
              <h2>Bästa salong- och frisörtjänster för dig </h2>
            </div>
              <div className="row">
                {loading ? "Loading..." : renderServices()}
              </div>
          </div>
      </div>
      {renderServiceDetailsModal()}
    </div>
  );
}

export default ServicesPage;
