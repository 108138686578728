import React from 'react';
import { Router } from '@reach/router';

import AdminGalleryPage from './AdminGalleryPage/AdminGalleryPage';
import AdminPhotosPage from './AdminPhotosPage/AdminPhotosPage';

import './AdminAlbumsView.scss';

const AdminAlbumsView = () => {

  return (
    <div className="AdminAlbumsView">
     <Router>
       <AdminGalleryPage default />
       <AdminPhotosPage path={`/:albumId/*`} />
     </Router>
    </div>
  )
}

export default AdminAlbumsView;
