import React, {useEffect, useState} from 'react';

import {showGalleryDetails} from '../../../services/navigation/navigation.service';
import { getAlbumsHome } from '../../../services/auth-service/auth.service';

import './GalleryComponent.scss';

const GalleryComponent = () => {
  
  const [loading, setLoading] = useState(true);
  const [albums, getAlbumsData] = useState();

  const getAlbumsFail = error => {
    setLoading(false);
    console.log(error);
  }

  const getAlbumsSuccess = response => {
    setLoading(false);
    getAlbumsData(response.data.data);
  }

  useEffect(() => {
    setLoading(true);
    getAlbumsHome()
      .then(getAlbumsSuccess)
      .catch(getAlbumsFail);
  },[])

  const galleryDetails = galleryId => {
    showGalleryDetails(galleryId);
  }

  const renderAlbumThumb = data => {
    const {id, name, cover_image} = data;
    return (
      <div className="col-lg-4 col-md-6 col-sm-12" key={id}>
        <div className="ImageEffect" onClick={galleryDetails.bind(null, id)}>
          <img src={process.env.REACT_APP_API_URL + '/' +  cover_image} alt="img04"/>
          <div className="ImageCaption">
            <h2>{name}</h2>
          </div>     
        </div>
      </div>
    )
  }

  const renderAlbums = () => {
    return albums?.map(renderAlbumThumb)
  }

  return (
    <div className="GalleryComponent">
      <div className="container">
        <div className="section-header text-center">
          <p>Frisör bildgalleri</p>
          <h2>Några bilder från vårt frisörgalleri</h2>
        </div>
        <div className="row gallery-container">
          {loading ? "Loading..." : renderAlbums()}
        </div>
      </div>
    </div>
  );
}

export default GalleryComponent;

