import React, {forwardRef, useState, useImperativeHandle} from 'react';
import {Card} from 'antd';
import {CloseOutlined} from '@ant-design/icons';

import './ModalDialog.scss';

const ModalDialog = forwardRef((props, ref) => {
  const [display, setDisplay] = useState(props.active || false);
  const [actions, setActions] = useState(true);

  useImperativeHandle(ref, () => {
    return {
      openModal: () => open(),
      closeModal: () => close(),
      disableActions: loading => disableActions(loading)
    }
  });

  const disableActions = loading => {
      setActions(!loading)
  };

  const open = () => {
    setDisplay(true);
  };

  const close = () => {
    setDisplay(false);
    if(props.handleClose) {
      props.handleClose();
    }
  };

  const getModalHeader = () => {
    let header = null;
    const {title} = props;
    if (title) {
      header = (
        <div className="ModalDialogHeader">
          {getModalTitle()}
        </div>
      );
    }

    return header;
  };

  const getModalTitle = () => {
    return (
      <div className="ModalDialogTitle">
        {props.title}
        <div className="ModalDialogTitle-actions">
          <CloseOutlined onClick={close}/>
        </div>
      </div>
    );
  };

  if (display) {
    const classes = ['ModalContainer'];
    const backgroundClasses = ['Background']
    if(props.className) classes.push(props.className);

    if(!actions) {
      classes.push('disabled'); 
      backgroundClasses.push('disabled'); 
    }

    return (
      <div className="ModalDialog">
        <div className={backgroundClasses.join(' ')} onClick={close}></div>
        <div className={classes.join(' ')} style={props.style}>
          {getModalHeader()}
          <Card>
            <div className="ModalDialogBody">
              {props.children}
            </div>
          </Card>
        </div>
      </div>
    )
  }

  return null;

});

export default ModalDialog;