import axios from 'axios';

export const urls = {
  logout: '/api/test',
  login: '/api/login',
  register: '/api/register',
  me: '/api/me',
  addAlbum: '/api/addAlbum',
  getAlbum: '/api/getAlbums',
  getAlbumsHome:'/api/getAlbumsHome',
  deleteAlbum: '/api/deleteAlbums',
  addPhotos: '/api/addPhotos',
  getPhotos:'/api/getPhotos',
  deletePhoto: '/api/deletePhoto',
  getServices: '/api/getServices',
  getServicesHome: '/api/getServicesHome',
  addService:'/api/addService',
  updateService:'/api/updateService',
  deleteService:'/api/deleteService'
};

export const authLogin = data => {
  return axios.post(urls.login, data, {
    headers: {
      doNotAddUserTokenAuthorization: true
    }
  });
};

export const authRegister = data => {
  return axios.post(urls.register, data, {
    headers: {
      doNotAddUserTokenAuthorization: true
    }
  });
};

export const authLogout = () => {
  return axios.get(`${urls.logout}`);
};

export const authMe = () => {
  return axios.get(`${urls.me}`);
};

// GALLERY !!!!!

export const getAlbums = () => {
  return axios.get(`${urls.getAlbum}`);
};

export const getAlbumsHome = () => {
  return axios.get(`${urls.getAlbumsHome}`);
};

export const addAlbum = (data, formData) => {
  formData.append(
    "name",
    data.name
  );
  return axios.post(urls.addAlbum, formData);
};

export const deleteAlbum = albumId => {
  const deleteAlbumData = {
    _method: "DELETE"
  }
  return axios.post(`${urls.deleteAlbum}/${albumId}`, deleteAlbumData);
};

// PHOTOS !!!!!

export const getPhotos = albumId => {
  return axios.get(`${urls.getPhotos}/${albumId}`);
};

export const addPhotos = (data, formData) => {
  formData.append(
    "album_id",
    data.album_id
  );
  return axios.post(urls.addPhotos, formData);
};

export const deletePhoto = photoId => {
  const deletePhotoData = {
    _method: "DELETE"
  }
  return axios.post(`${urls.deletePhoto}/${photoId}`, deletePhotoData);
};

// SERVICES !!!!!

export const getServices = () => {
  return axios.get(`${urls.getServices}`);
};

export const getServicesHome = () => {
  return axios.get(`${urls.getServicesHome}`);
};

export const addService = (data, formData) => {
  formData.append(
    "title",
    data.title
  );
  formData.append(
    "price",
    data.price
  );
  formData.append(
    "time",
    data.time
  );
  formData.append(
    "description",
    data.description
  );
  return axios.post(urls.addService, formData);
};

export const updateService = (data, formData, serviceId) => {
  console.log("formData ==>", formData);
  formData.append(
    "title",
    data.title
  );
  formData.append(
    "price",
    data.price
  );
  formData.append(
    "time",
    data.time
  );
  formData.append(
    "description",
    data.description
  );
  formData.append(
    "_method",
    "PATCH"
  );
  return axios.post(`${urls.updateService}/${serviceId}`, formData);
};

export const deleteService = serviceId => {
  const deleteServiceData = {
    _method: "DELETE"
  }
  return axios.post(`${urls.deleteService}/${serviceId}`, deleteServiceData);
};
