export const TOKEN = 'token';
export const EXPIRES = 'expires';

export const cleanupToken = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(EXPIRES);
};

export const getToken = () => {
  return localStorage.getItem(TOKEN);
};

export const getUserTokenExpiration = () => {
  return localStorage.getItem(EXPIRES);
};

export const hasTokenExpired = () => {
  const expires = getUserTokenExpiration();
  return !!(expires && expires < new Date().getTime());
};

export const saveToken = (token, tokenDuration) => {
  localStorage.setItem(TOKEN, token);
  const now = new Date().getTime();
  localStorage.setItem(EXPIRES, now + tokenDuration * 60 * 1000);
}