import React, {useState, useRef} from 'react';

import { Button, message } from 'antd';

import {useForm} from 'react-hook-form';
import { updateService } from '../../../../../../../../../services/auth-service/auth.service';

import './EditServiceModal.scss';

const EditServiceModal = props => {
  const {modalRef, closeModal, reloadCurrentPage, serviceData} = props;
  const [coverImage, getCoverImage] = useState();
  const {register, handleSubmit} = useForm();
  const submitRef = useRef(null);
  const [loading, setLoading] = useState(false);
  
  const succesMessage = () => {
    message.success("Gallery saved");
  }

  const errorMessage = () => {
    message.error("Gallery not saved!!!");
  }

  const successUpdateGallery = response => {
    setLoading(false);
    reloadCurrentPage();
    closeModal(modalRef)
    succesMessage();
    console.log('response ==> ', response);
  }

  const failUpdateGallery = error => {
    setLoading(false);
    errorMessage();
    console.log('error ==> ', error);
  }
  
  const triggerSubmit = () => {
    console.log("CLICK");
    submitRef.current.click();
  }
  
  const handleChange = file => {
    getCoverImage(file[0])
  }

  const onSubmit = data => {
    
    const formData = new FormData();
    setLoading(true);
    if (data.photo.length > 0 ){
      formData.append(
        "photo",
        coverImage,
        coverImage.name
      );
    }   

    updateService(data, formData, data.id)
      .then(successUpdateGallery)
      .catch(failUpdateGallery);
  }

  const renderAddServiceForm = () => {
    const {id, title, price, time, description} = serviceData;
    return (
      <form className="FormContainer" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <input type="id"
               placeholder="id"
               hidden={true}
               defaultValue={id}
               {...register('id')}
               />
        <input type="text"
               placeholder="Title"
               defaultValue={title}
               {...register('title')}
               />
        <textarea type="textarea"
               placeholder="Description"
               defaultValue={description}
               rows="7"
               {...register('description')}
               />
        <input type="text"
               placeholder="Price"
               defaultValue={price}
               {...register('price')}
               />
        <input type="text"
               placeholder="Time"
               defaultValue={time}
               {...register('time')}
               />
        <input type="file"
              //  hidden={true}
               placeholder="Image"
               {...register('photo')}
               onChange={e => handleChange(e.target.files)}
               />
        <Button className="SubmitButton"
                loading={loading}
                onClick={triggerSubmit}
                >
          Update Service
        </Button>
        <button type="submit"
                hidden={true}
                ref={submitRef}/>
      </form>
    )
  }

  return (
    <div className="EditServiceModal">
      {renderAddServiceForm()}
    </div>
  )
}

export default EditServiceModal;
