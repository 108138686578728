import React, {useRef, useState} from 'react';
import {useForm} from 'react-hook-form';


import {addPhotos} from '../../../../../../../../../../services/auth-service/auth.service';

import { Button, message } from 'antd';

import './AddPhotosModal.scss';

const AddPhotosModal = props => {
  const {albumId, hideAddPhotosModal, reloadCurrentPage} = props;
  const {register, handleSubmit} = useForm();
  const submitRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const triggerSubmit = () => {
    submitRef.current.click();
  }

  const addPhotosSuccess = response => {
    succesMessage();
    hideAddPhotosModal();
    reloadCurrentPage();
    setLoading(false);
    console.log('response ==> ', response);
  }

  const addPhotosFailed = error => {
    errorMessage();
    setLoading(false);
    console.log('error ==> ', error);
  }

  const succesMessage = () => {
    message.success("Photos saved");
  }

  const errorMessage = () => {
    message.error("Photos not saved!!!");
  }

  const onSubmit = data => {
    const formData = new FormData();
    
    for(let i =0; i < data.photo.length; i++){
      formData.append('photo[]', data.photo[i]);
    }
    setLoading(true);
    addPhotos(data, formData)
      .then(addPhotosSuccess)
      .catch(addPhotosFailed);
  }

  const renderForm = () => {
    return (
      <div className="AddPhotoFormContainer">
        <form className="FormContainer" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          <input type="text"
                hidden
                {...register('album_id', { required: true })}
                defaultValue={albumId}
                />
          {/* <input type="text"
                placeholder="Title"
                {...register('title')}
                /> */}
          <input type="file"
                placeholder="Photo"
                multiple={true}
                {...register('photo[]', { required: true })}
                />
          <Button className="SubmitButton"
                  loading={loading}
                  onClick={triggerSubmit}>
            Add Photos
          </Button>
          <button type="submit"
                  hidden={true}
                  ref={submitRef}/>
        </form>
      </div>
    ) 
  }

  return (
    <div className="AddPhotosModal">
      {renderForm()}
    </div>
  )
}

export default AddPhotosModal;
