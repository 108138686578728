import React, {useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import { Button } from 'antd';

import LOGO from '../../../images/logo.png';

import {authLogin} from '../../../services/auth-service/auth.service';
import {saveToken} from '../../../services/token-service/token.service';
import {setUserLocalData} from '../../../services/user-service/user.service';
// import {showAdminGallery} from '../../../services/navigation/navigation.service';

import './LoginPage.scss';

const LoginPage = () => {
  const submitRef = useRef(null);
  const {register, handleSubmit} = useForm();
  const [loading, setLoading] = useState(false);

  const refreshPage = () => {
    window.location.reload();
  }

  const handleLoginFailure =  error => {
    console.log('error', error);
    setLoading(false);
  }

  const handleLoginSuccess = response => {
    setLoading(false);
    const {data} = response;
    refreshPage();
    saveToken(data.access_token, data.expires_in);
    setUserLocalData(data);
  }

  const onSubmit = data => {
    setLoading(true);
    authLogin(data)
      .then(handleLoginSuccess)
      .catch(handleLoginFailure);
  }

  const triggerSubmit = () => {
    submitRef.current.click();
  }

  return (
    <div className="LoginPage">
      <div className="LogoContainer">
        <img className="logo" src={LOGO} alt="alexglad" />
      </div>
      <form className="FormContainer" onSubmit={handleSubmit(onSubmit)}>
        <input type="text"
               placeholder="E-mail"
               {...register('email', { required: true })}
               />
        <input type="password"
               placeholder="Enter password"
               {...register('password', { required: true })}
               />
        <Button className="SubmitButton"
                loading={loading}
                onClick={triggerSubmit}>
          Submit
        </Button>
        <button type="submit"
                hidden={true}
                ref={submitRef}/>
      </form>
    </div>
  );
}

export default LoginPage;
