import React from 'react';

import AboutUsComponent from '../../components/AboutUsComponent/AboutUsComponent';
import ServicesComponent from '../../components/ServicesComponent/ServicesComponent';
import TestimonialComponent from '../../components/TestimonialComponent/TestimonialComponent';
import GalleryComponent from '../../components/GalleryComponent/GalleryComponent';
import SliderComponent from '../../components/SliderComponent/SliderComponent';


const HomePage = () => {

  return (
    <div>
      <SliderComponent />
      <AboutUsComponent />
      <ServicesComponent />
      <TestimonialComponent />
      <GalleryComponent />
    </div>
  );
}

export default HomePage;
