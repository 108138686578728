import React from 'react';
import './ServiceDetailsModal.scss';

const ServiceDetailsModal = props => {
  const {data} = props;

  return (
    <div className="ServiceDetailsModal">
      <div className="ServiceDetailsModalHeader">
        <div className="Title">{data.title}</div>
        <div className="PriceTimeContainer">
          <div className="Price">{data.price} kr</div>
          <div className="Time">{data.time} minuter</div>
        </div>
      </div>
      <div className="ServiceDetailsModalContent">
        <div className="ImageContainer">
        <img src={process.env.REACT_APP_API_URL + '/' + data.photo} alt="alexglad" />
        </div>
        {data.description && <div className="DescriptionContainer">
          {data.description}
        </div>}
      </div>
    </div>
  )
}

export default ServiceDetailsModal;
