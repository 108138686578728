import React from 'react';
// import {Router} from '@reach/router';
import 'antd/dist/antd.css';

import LoginPage from './LoginPage/LoginPage';
import DashboardPage from './DashboardPage/DashboardPage';

import {getUserLocalData} from '../../services/user-service/user.service';

const AdminView = () => {
  const userData = getUserLocalData();
    
  return (
    <div>
      {Object.keys(userData).length > 1 ? <DashboardPage /> : <LoginPage userData={userData} /> }
    </div>
  );
}

export default AdminView;
