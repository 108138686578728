import React, {useEffect, useState} from 'react';

import { Carousel, Button } from 'react-bootstrap';

import BANNER1 from '../../../images/slider/banner_1.jpg';
import BANNER2 from '../../../images/slider/banner_2.jpg';
import BANNER1MOBILE from '../../../images/slider/banner_1_mobile.jpg';
import BANNER2MOBILE from '../../../images/slider/banner_2_mobile.jpg';

import './SliderComponent.scss';

const SliderComponent = () => {
  const [windowWidth, getWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      getWindowWidth(window.innerWidth);
    }
    
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [windowWidth])

  const RedirectBooking = e => {
    e.preventDefault();
    window.open(
      'https://www.bokadirekt.se/places/alex-glad-hair-care-40921',
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  return (
    <div className="SliderComponent">
      <Carousel fade interval={5000}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={windowWidth > 768 ? BANNER1 : BANNER1MOBILE}
            alt="First slide"
          />
          <Carousel.Caption>
            <Button onClick={RedirectBooking} className="SliderButton" variant="outline-warning">Boka tid</Button>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={windowWidth > 768 ? BANNER2 : BANNER2MOBILE}
            alt="Second slide"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default SliderComponent;
