import {navigate} from "@reach/router";

import {getGalleryBaseRoute, getGalleryPhotosBaseRoute} from './gallery-navigation-routes.service';

export const showGallery = () => {
  return navigate(getGalleryBaseRoute());
}

export const showGalleryPhotosPage = albumId => {
  return navigate(getGalleryPhotosBaseRoute(albumId))
}