import React from 'react';
import { Layout } from 'antd';
import {Router} from '@reach/router';
import {MAIN_DASHBOARD_VIEW_ROUTES} from '../../main-dashboard-view.routes';

import AdminDashboardPage from './components/AdminDashboardPage/AdminDashboardPage';
import AdminAlbumsView from './components/AdminAlbumsView/AdminAlbumsView';
import AdminServicesPage from './components/AdminServicesPage/AdminServicesPage';

import './MainContent.scss';

const MainContent = () => {

  return (
    <Layout>
      <Layout.Content className="MainContent">
        <Router>
          <AdminDashboardPage default />
          <AdminAlbumsView path={`${MAIN_DASHBOARD_VIEW_ROUTES.GALLERY}/*`} />
          <AdminServicesPage path={`${MAIN_DASHBOARD_VIEW_ROUTES.SERVICES}/*`} />
        </Router>  
      </Layout.Content>
    </Layout>
  )
}

export default MainContent;
